<template>
  <div>
    <sideBarUserAvatar />

    <v-date-picker class="wsRoundedHalf" no-title
                   locale="uk-UA"/>
<!--    <h4 class="mt-6">3 Cічня</h4>-->
<!--    <div class="mt-3 d-flex align-center">-->
<!--      <v-sheet   class="wsRoundedHalf d-flex align-center justify-center mr-3"-->
<!--                 color="#384449"  min-width="40"-->
<!--                 dark height="40" width="40">-->
<!--        <v-icon color="#e3ecf1">mdi-book-open-page-variant</v-icon>-->
<!--      </v-sheet>-->
<!--      <div>-->
<!--        <h5>Контрольна робота: Ділова україньска мова, Модуль 1</h5>-->
<!--      </div>-->
<!--    </div>-->
<!--    <h4 class="mt-6">Новини</h4>-->
<!--    <div class="d-flex mt-3 align-center">-->
<!--      <v-img class="mr-2 wsRoundedHalf"  aspect-ratio="1" width="60" src="@/assets/brand/university_logo.png" />-->
<!--      <div>-->
<!--        <h5 class="font-weight-medium">Новорічне привітання від ректора університету</h5>-->
<!--        <h6 class="font-weight-medium mt-1">7 Грудня 2021</h6>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="d-flex mt-3 align-center">-->
<!--      <v-img class="mr-2 wsRoundedHalf"  aspect-ratio="1" width="60" src="@/assets/img/business_conference.png" />-->
<!--      <div>-->
<!--        <h5 class="font-weight-medium"> Наукова конференція "IT іновації в сфері освіти"</h5>-->
<!--        <h6 class="font-weight-medium mt-1">1 Cічня 2022</h6>-->
<!--      </div>-->
<!--    </div>-->
<!--    <h4 class="mt-6">Події</h4>-->
<!--    <h5 class="mt-3"><v-icon small>mdi-chevron-right</v-icon>Завдання <span :style="`color: ${wsLIGHTCARD}` ">Практична робота 3 </span> оцінено</h5>-->

  </div>
</template>

<script>
import sideBarUserAvatar from "@/components/pages/dashboard/UI/sidebar/sideBarUserAvatar";
export default {
  name: "pageSideStudentDefault",
  components : {
    sideBarUserAvatar
  }
}
</script>

<style scoped>
.v-picker >>> .v-picker__body  {
  background-color: #4e7d95 !important ;
}
</style>