<template>
  <div>
    <sideBarUserAvatar />
    <v-date-picker class="wsRoundedHalf" no-title
                   locale="uk-UA"/>

  </div>
</template>

<script>
import sideBarUserAvatar from "@/components/pages/dashboard/UI/sidebar/sideBarUserAvatar";
export default {
  name: "pageSideStudentDefault",
  components : {
    sideBarUserAvatar
  }
}
</script>

<style scoped>
.v-picker >>> .v-picker__body  {
  background-color: #4e7d95 !important ;
}
</style>