<template>
  <div class="fill-height" >
    <v-sheet
              :class="!SM ? 'wsRounded' : 'wsRoundedTop' "
              class="fill-height " >

      <v-row  no-gutters  style="flex-wrap: nowrap;" class="fill-height" >
        <!-- :: MAIN CONTENT ::-->
        <v-col    style="min-width: 100px; max-width: 100%;" class="flex-grow-1 wsRounded flex-shrink-0 ">

          <v-fade-transition mode="out-in">
            <v-sheet v-if="!displayDetailsMobile" color="transparent" key="mainAll" id="dashPageContent"
                     class="fill-height  wsRounded"
                     style="justify-self: stretch"
                     :class="mainContentClass" >


              <div v-if="SM">
                <div  class="d-flex align-center justify-space-between ">
                  <div  class="d-flex align-center">
                    <v-btn v-if="icon" :to="headerRoute"   icon class="mr-3" :color="wsACCENT">
                      <v-icon  >{{ icon ? icon : 'mdi-chevron-left' }}</v-icon>
                    </v-btn>
                    <h5 class="font-weight-medium">{{ mobileTitle }}</h5>
                  </div>
                  <slot name="header_mobile_action_side"></slot>
                  <v-btn v-if="!noSide" @click="displayDetailsMobile = true" icon><v-icon>mdi-dots-horizontal</v-icon></v-btn>

                </div>
                <div>
                  <slot name="header_mobile_action_bottom"></slot>
                </div>
              </div>



              <!-- Header : Title and Subtitile -->
              <div v-if="title && !SM" class="d-flex align-center justify-space-between ">
                <div class="d-flex align-center ">
                  <v-btn :to="headerRoute"   icon class="mr-3" :color="wsACCENT">
                    <v-icon  >{{ icon ? icon : 'mdi-chevron-left' }}</v-icon>
                  </v-btn>

                  <div>
                    <h3 class="font-weight-medium" v-if="title" style="line-height: 1.2">{{ title }} </h3>
                    <h5 v-if="subtitle" class="font-weight-medium">{{ subtitle }}</h5>
                  </div>
                </div>

                <div>
                  <slot name="header_action"></slot>
                </div>


              </div>
              <!-- Default Slot content -->
              <slot name="default"></slot>
              <slot v-if="!SM" name="defaultDesktop"></slot>
              <slot v-if="SM" name="defaultMobile"></slot>
              <slot v-if="!SM" name="default.desktop"></slot>
              <slot v-if="SM" name="default.mobile"></slot>
              <slot name="default.after"></slot>

            </v-sheet>
            <v-sheet  v-if="SM && displayDetailsMobile"
                      style="justify-self: stretch"
                      class="fill-height wsRoundedTop sideTransition fill-height overflow-y-auto "
                      :color="wsDARKLIGHT" dark key="mobileSide">

              <div v-if="SM" class="d-flex align-center justify-space-between px-6 py-3">
                <h5 class="font-weight-medium">{{ mobileSideTitle }}</h5>
                <v-btn @click="displayDetailsMobile = false" icon><v-icon>mdi-close</v-icon></v-btn>
              </div>

              <v-divider  class="mb-3"/>

              <div class="px-6">

                <pageSideStudentDefault v-if="sideStudent" />
                <pageSideTeacherManagerDefault v-if="sideAdminManager" />

                <v-sheet v-if="showSearch" color="transparent" width="250">
                  <v-text-field
                      prepend-inner-icon="mdi-magnify"
                      :label="$t('Search')"
                      dense
                      hide-details
                      outlined />
                </v-sheet>

                <slot :collapse="hideMobileDetails" name="side_top"></slot>

                <!-- Navigation bar set -->
                <div v-if="sideNavigation.length > 0">
                  <h5 class="mt-6">{{ $t('Navigation') }}</h5>
                  <v-list color="transparent" dense class="pt-3">
                    <v-list-item-group mandatory  v-model="value">
                      <div v-for="(item,i) in sideNavigation" :key="i">
                        <router-link v-if="!sideNavigationNoLink" :to="businessDashLink(`${navigationRoute}/${item.value}`)" class="noUnderline">
                          <v-list-item :value="item.value" color="white" >
                            <v-list-item-icon > <v-icon>{{  item.icon  }}</v-icon> </v-list-item-icon>
                            <v-list-item-title>{{  item.text  }}</v-list-item-title>
                          </v-list-item>
                        </router-link>
                        <v-list-item v-else :value="item.value" color="white" >
                          <v-list-item-icon > <v-icon>{{  item.icon  }}</v-icon> </v-list-item-icon>
                          <v-list-item-title>{{  item.text  }}</v-list-item-title>
                        </v-list-item>
                      </div>

                    </v-list-item-group>

                  </v-list>

                </div>

                <!-- Sidebar Slot Content-->
                <slot :collapse="hideMobileDetails" class="fill-height" name="side"> </slot>
              </div>



            </v-sheet>
          </v-fade-transition>

        </v-col>

        <!-- :: SIDEBAR :: -->
        <v-col v-if="!noSide && !SM "  :style="`min-width: ${width}px; max-width: ${width}px;`" class="flex-grow-0 flex-shrink-0 sideTransition">
          <v-sheet  dark
                   class="fill-height page-sidebar py-8  sideTransition fill-height"
                   :class="collapse ? '' : 'px-6'"
                   :color="wsDARK"
                   style="height: 100%"  :max-width="width" :min-width="width">


            <!-- Header and Collapse button-->
            <div class="d-flex    "
                 :class="collapse ? 'justify-center' : 'justify-space-between'">

              <h5 v-if="!collapse" class="font-weight-medium">{{ $t('Details') }} :</h5>
              <v-btn icon @click="collapseSideBar">
                <v-icon>{{ collapse ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
              </v-btn>
            </div>

            <v-divider class="mb-6 mt-3" />

            <div v-if="!collapse">

              <!-- Default content sets -->
              <sideBarUserAvatar v-if="sideUser" :user="user" />

              <pageSideStudentDefault v-if="sideStudent" />
              <pageSideTeacherManagerDefault v-if="sideAdminManager" />

              <v-sheet v-if="showSearch" color="transparent" width="250">
                <v-text-field
                    prepend-inner-icon="mdi-magnify"
                    :label="$t('Search')"
                    dense
                    hide-details
                    outlined />
              </v-sheet>

              <slot :collapse="hideMobileDetails" name="side_top"></slot>

              <!-- Navigation bar set -->
              <div v-if="sideNavigation.length > 0">
                <h5 class="mt-6">{{ $t('Navigation') }}</h5>
                <v-list color="transparent" dense class="pt-3">
                  <v-list-item-group mandatory  v-model="value">
                    <div v-for="(item,i) in sideNavigation" :key="i">
                      <router-link v-if="!sideNavigationNoLink" :to="businessDashLink(`${navigationRoute}/${item.value}`)" class="noUnderline">
                        <v-list-item :value="item.value" color="white" >
                          <v-list-item-icon > <v-icon>{{  item.icon  }}</v-icon> </v-list-item-icon>
                          <v-list-item-title>{{  item.text  }}</v-list-item-title>
                        </v-list-item>
                      </router-link>
                      <v-list-item v-else :value="item.value" color="white" >
                        <v-list-item-icon > <v-icon>{{  item.icon  }}</v-icon> </v-list-item-icon>
                        <v-list-item-title>{{  item.text  }}</v-list-item-title>
                      </v-list-item>
                    </div>

                  </v-list-item-group>

                </v-list>

              </div>

              <!-- Sidebar Slot Content-->
              <slot :collapse="hideMobileDetails" class="fill-height" name="side"> </slot>
            </div>



          </v-sheet>
        </v-col>
      </v-row>

    </v-sheet>
    <slot name="dialog"></slot>
  </div>
</template>

<script>
import sideBarUserAvatar from "@/components/pages/dashboard/UI/sidebar/sideBarUserAvatar";
import pageSideStudentDefault from "@/components/pages/dashboard/UI/sidebar/pageSideStudentDefault";
import pageSideTeacherManagerDefault from "@/components/pages/dashboard/UI/sidebar/pageSideTeacherManagerDefault";
export default {
  name: "dashPage",
  components: {
    pageSideStudentDefault,
    pageSideTeacherManagerDefault,
    sideBarUserAvatar
  },
  props : {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    icon: {
      type: String
    },
    headerRoute : {
      type: String,
      default : ''
    },
    noPaddingMain: {
      type: Boolean,
      default : false
    },
    mainBackground: {
      type: String,
      default : 'transparent'
    },
    sideStudent : {
      type : Boolean,
      default : false
    },
    sideAdminManager : {
      type : Boolean,
      default : false
    },
    sideUser : {
      type : Boolean,
      default : false
    },
    sideNavigation : {
      type : Array,
      default: () => []
    },
    sideNavigationNoLink : {
      type : Boolean,
      default : false
    },
    navigationRoute  : {
      type : String,
      default : ''
    },
    noSide: {
      type: Boolean,
      default: false
    },
    showSearch : {
      type: Boolean,
      default: false
    },
    value : {
      type : String,
    },
    user : {
      type : Object,
      default() { return {} }
    },
    mobileSideTitle : {
      type : String,
      default : 'Details'
    },
    mobileTitle : {
      type : String,
      default : ''
    },
    overflowY : {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      width : 335,
      collapse : false,
      displayDetailsMobile : false,
    }
  },
  computed : {
    mainContentClass() {
      let overflow = this.overflowY ? ' overflow-y-auto' : ''
      let result = 'pa-8' + overflow
      if ( this.noPaddingMain ) {
        return '' + overflow
      }
      if ( this.displayShow(['sm']) ) {
        return 'py-3 px-3' +overflow
      }
      return result
    }
  },
  watch : {
    value(val) {
      this.$emit('input',val)
    },
    SM(value) {
      if (!value) {
        this.displayDetailsMobile = false
      }
    },
    windowSize(val,old) {
      if ( val === 'sm' ) {
        this.collapse = true
        this.width = 50
      }
      if ( old === 'sm' ) {
        this.collapse = false
        this.width = 335
      }
    }
  },
  methods : {
    hideMobileDetails() {
      this.displayDetailsMobile = false
    },
    collapseSideBar() {
      this.collapse = !this.collapse
      this.width = this.collapse ? 50 : 335
    }
  },
  mounted() {
    if (this.title ) {
      this.$store.state.dashboard.pageTitle = this.title
    }
  }
  }
</script>

<style scoped>
.page-sidebar {
  transition: all 0.3s ease;
}
.wsSecondary {
  color : #afb3b5;
  font-size: small;
}
.sideTransition  {
  transition: all 0.3s ease;
}
.mainContentStyle {
  position : absolute;
  top : 0 ;
  bottom : 0;
  left : 0;
  transition: all 0.3s ease;
}
</style>