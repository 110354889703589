<template>
  <div>

    <div v-if="!user.id">
      <div  class="d-flex justify-center">
          <v-avatar  v-if="!displayUpload " color="white" size="126">
            <v-avatar
                style="cursor: pointer"
                v-ripple
                @click="displayUpload = true"
                class="my-auto"
                :color="!$store.state.auth.userImg ? wsATTENTION : 'white'"
                size="120" key="profile_picture" >

              <v-img  v-if="$store.state.auth.userImg" :src="$store.state.auth.userImg" transition="xxx" >
                <template slot="placeholder">
                  <v-img src="@/assets/placeholder/user_blank.jpg" transition="xxx" />
                </template>
              </v-img>
              <v-icon v-else size="80">mdi-account</v-icon>

            </v-avatar>
          </v-avatar>
          <div v-if="displayUpload">
            <input style="display: none" id="fileInput" type="file"  @change="fileBrowsed">
            <v-avatar
                class="pointer"
                @click="selectFile"
                :color="dropzoneHover ? 'grey darken-2' : 'grey lighten-2'"
                size="200"
                :set="hover = false"
                @dragover.prevent="dropzoneHover=true"
                @dragleave.prevent="dropzoneHover=false"
                @drop.prevent="drop"
                style="transition: all 0.3s ease; position: relative"
                min-height="200px">
              <div v-if="!LOADING">
                <v-icon x-large>mdi-tray-arrow-down</v-icon>
                <h4>{{$t('DragToUpload')}}</h4>
              </div>
              <v-progress-circular v-else indeterminate />
            </v-avatar>
            <v-btn v-if="$store.state.auth.userImg" @click="displayUpload = false" block text class="noCaps" small>{{$t('Back')}}</v-btn>
          </div>
      </div>
      <h4 class="text-center mt-3">{{ $store.state.auth.userName }}</h4>
      <h5 class="text-center font-weight-medium" >{{ $store.state.auth.userEmail }}</h5>
    </div>

    <div v-else>
      <div  class="d-flex justify-center">
        <v-avatar color="white" size="126">
          <v-avatar
              style="cursor: pointer"
              v-ripple
              @click="displayUpload = true"
              class="my-auto"
              :color="!user.img ? wsATTENTION : 'white'"
              size="120" key="profile_picture" >

            <v-img  v-if="user.img" :src="user.img" transition="xxx">
              <template slot="placeholder">
                <v-img src="@/assets/placeholder/user_blank.jpg" transition="xxx" />
              </template>
            </v-img>
            <v-icon v-else size="80">mdi-account</v-icon>

          </v-avatar>
        </v-avatar>
      </div>
    </div>
    <h4 class="text-center mt-3">{{ user.firstname }} {{ user.lastname }}</h4>
    <h5 class="text-center font-weight-medium" >{{ user.email }}</h5>

    <v-divider class="my-6"></v-divider>

  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "sideBarUserAvatar",
  props : {
    user : {
      type : Object,
      default () { return {} }
    }
  },
  data() {
    return {
      displayUpload : false,
      dropzoneHover : false,
    }
  },
  methods : {
    ...mapActions('auth',  ['UPLOAD_USER_IMAGE' ]),
    drop(e) {
      let file = e.dataTransfer.files[0];
      this.uploadAvatar(file)
    },
    fileBrowsed(e) {
      let file = e.target.files[0];
      this.uploadAvatar(file)
    },
    selectFile() {
      document.getElementById('fileInput').click()
    },
    async uploadAvatar(file) {

      let result = await this.UPLOAD_USER_IMAGE({ file : file })
      if (!result) { return this.notify(this.$t('Error')) }
      this.$store.state.auth.userImg = result.url
      this.displayUpload = false

    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>